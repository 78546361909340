<template>
    <div class="wrapper productionCosts">
        <table class="custom-table">
            <thead>
                <tr>
                    <!-- <td class="custom-th custom-th-bg">
                        序号
                    </td> -->
                    <td class="custom-th custom-th-bg" style="min-width:.6rem;">
                        品种
                    </td>
                    <td class="custom-th custom-th-bg" style="min-width:.1rem;">
                        项目
                    </td>
                    <template v-for="(item, index) in directData.costsDirectInfos">
                        <td class="custom-th custom-th-bg mongth-th" :key="'th'+index" v-if="item.date>0">
                            {{ item.date }}月
                        </td>
                        <td class="custom-th custom-th-bg mongth-th" :key="'th'+index" v-if="item.date==0">
                            总计
                        </td>
                    </template>
                </tr>
            </thead>
            <tbody>
                <!-- 一、直接材料 -->
                <tr>
                    <td class="custom-th custom-th-odd" style="min-width:.6rem;">
                        一、直接材料
                    </td>
                    <td class="custom-th padding0">
                        <table class="custom-table inner-table">
                            <tbody>
                                <tr>
                                    <td>总用量(吨)</td>
                                </tr>
                                <tr>
                                    <td>总金额(元）</td>
                                </tr>
                                <tr>
                                    <td>容重（吨/方）</td>
                                </tr>
                                <tr>
                                    <td>单位金额（元/方）</td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                    <template v-for="(item, index) in directData.costsDirectInfos">
                        <td class="padding0" :key="`costsDirectInfos${index}`">
                            <table class="custom-table inner-table" v-if="isDrill">
                                <tbody>
                                    <tr>
                                        <td @click="showDataDrills1(item,1,1)">
                                            <a href="javascript:;">{{ item.direct_weight }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills1(item,2,1)">
                                            <a href="javascript:;">{{ item.direct_money }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills1(item,6,1)">
                                            <a href="javascript:;">{{ item.direct_unitconsumption }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills1(item,4,1)">
                                            <a href="javascript:;">{{ item.direct_settlementprice }}</a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table class="custom-table inner-table" v-else>
                                <tbody>
                                    <tr>
                                        <td>
                                            {{ item.direct_weight }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ item.direct_money }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ item.direct_unitconsumption }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ item.direct_settlementprice }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </template>
                </tr>
                <template v-for="(term, termIndex) in directData.costsDirectTypeReports">
                    <tr :key="`TypeReports${termIndex}`">
                        <td class="custom-th td-class2" :class="{'border6':term.costsDirectTypeInfos<=0}">
                            {{ term.label }}
                        </td>
                        <td class="custom-th padding0" :class="{'border6':term.costsDirectTypeInfos<=0}">
                            <table class="custom-table inner-table">
                                <tbody>
                                    <tr>
                                        <td>总用量(吨)</td>
                                    </tr>
                                    <tr>
                                        <td>总金额(元）</td>
                                    </tr>
                                    <tr>
                                        <td>单耗（吨/方）</td>
                                    </tr>
                                    <tr>
                                        <td>结算价格(元/吨)</td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <template v-for="(item, index) in term.costsDirectType">
                            <td class="padding0" :key="`costsDirectType${index}`" :class="{'border6':term.costsDirectTypeInfos<=0}">
                                <table class="custom-table inner-table" v-if="isDrill">
                                    <tbody>
                                        <tr>
                                            <td @click="showDataDrills2(item,1,1)">
                                                <a href="javascript:;">{{ item.weight }}</a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td @click="showDataDrills2(item,2,1)">
                                                <a href="javascript:;">{{ item.money }}</a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td @click="showDataDrills2(item,3,1)">
                                                <a href="javascript:;">{{ item.unitconsumption }}</a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td @click="showDataDrills2(item,4,1)">
                                                <a href="javascript:;">{{ item.settlementprice }}</a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table class="custom-table inner-table" v-else>
                                    <tbody>
                                        <tr>
                                            <td>
                                                {{ item.weight }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {{ item.money }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {{ item.unitconsumption }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {{ item.settlementprice }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </template>
                    </tr>
                    <template v-for="(v, vIndex) in term.costsDirectTypeInfos">
                        <tr :key="`${v.label+vIndex}`" :class="{'border6': vIndex===term.costsDirectTypeInfos.length-1}">
                            <td class="td-class3">
                                {{ v.label }}
                            </td>
                            <td class="custom-th padding0">
                                <table class="custom-table inner-table">
                                    <tbody>
                                        <tr>
                                            <td>总用量(吨)</td>
                                        </tr>
                                        <tr>
                                            <td>总金额(元）</td>
                                        </tr>
                                        <tr>
                                            <td>单耗（吨/方）</td>
                                        </tr>
                                        <tr>
                                            <td>结算价格(元/吨)</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                            <template v-for="(detailsItem, detailsIndex) in v.costsDirectTypeDetails">
                                <td class="padding0" :key="`costsDirectTypeDetails${detailsIndex}`">
                                    <table class="custom-table inner-table" v-if="isDrill">
                                        <tbody>
                                            <tr>
                                                <td @click="showDataDrills2(detailsItem,1,2)">
                                                    <a href="javascript:;">{{ detailsItem.weight }}</a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td @click="showDataDrills2(detailsItem,2,2)">
                                                    <a href="javascript:;">{{ detailsItem.money }}</a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td @click="showDataDrills2(detailsItem,3,2)">
                                                    <a href="javascript:;">{{ detailsItem.unitconsumption }}</a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td @click="showDataDrills2(detailsItem,4,2)">
                                                    <a href="javascript:;">{{ detailsItem.settlementprice }}</a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table class="custom-table inner-table" v-else>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    {{ detailsItem.weight }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    {{ detailsItem.money }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    {{ detailsItem.unitconsumption }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    {{ detailsItem.settlementprice }}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </template>
                        </tr>
                    </template>
                </template>
                <!-- 水、电 -->
                <tr>
                    <td class="padding0">
                        <table class="custom-table inner-table">
                            <tbody>
                                <tr class="border6">
                                    <td class="custom-th custom-th-even">
                                        二.水
                                    </td>
                                </tr>
                                <tr class="border6">
                                    <td class="custom-th custom-th-odd">
                                        三.电力
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                    <td class="padding0">
                        <table class="custom-table inner-table">
                            <tbody>
                                <tr>
                                    <td class="custom-th">
                                        总用量(吨)
                                    </td>
                                </tr>
                                <tr>
                                    <td class="custom-th">
                                        总金额(元)
                                    </td>
                                </tr>
                                <tr>
                                    <td class="custom-th">
                                        单耗（吨/方）
                                    </td>
                                </tr>
                                <tr class="border6">
                                    <td class="custom-th">
                                        结算价格(元/吨)
                                    </td>
                                </tr>
                                <tr>
                                    <td class="custom-th">
                                        总用量(kwh)
                                    </td>
                                </tr>
                                <tr>
                                    <td class="custom-th">
                                        总金额(元)
                                    </td>
                                </tr>
                                <tr>
                                    <td class="custom-th">
                                        单耗（kwh/方）
                                    </td>
                                </tr>
                                <tr class="border6">
                                    <td class="custom-th">
                                        结算价格(元/kwh)
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                    <template v-for="(waterpower, wpIndex) in waterpowerData">
                        <td class="padding0" :key="`wp${wpIndex}`">
                            <table class="custom-table inner-table" v-if="isDrill">
                                <tbody>
                                    <tr>
                                        <td class="custom-th" @click="showDataDrills1(waterpower,1,2)">
                                            <a href="javascript:;">{{ waterpower.water_weight }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="custom-th" @click="showDataDrills1(waterpower,2,2)">
                                            <a href="javascript:;">{{ waterpower.water_money }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="custom-th" @click="showDataDrills1(waterpower,3,2)">
                                            <a href="javascript:;">{{ waterpower.water_unitconsumption }}</a>
                                        </td>
                                    </tr>
                                    <tr class="border6">
                                        <td class="custom-th" @click="showDataDrills1(waterpower,5,2)">
                                            <a href="javascript:;">{{ waterpower.water_settlementprice }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="custom-th" @click="showDataDrills1(waterpower,1,3)">
                                            <a href="javascript:;">{{ waterpower.power_weight }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="custom-th" @click="showDataDrills1(waterpower,2,3)">
                                            <a href="javascript:;">{{ waterpower.power_money }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="custom-th" @click="showDataDrills1(waterpower,3,3)">
                                            <a href="javascript:;">{{ waterpower.power_unitconsumption }}</a>
                                        </td>
                                    </tr>
                                    <tr class="border6">
                                        <td class="custom-th" @click="showDataDrills1(waterpower,5,3)">
                                            <a href="javascript:;">{{ waterpower.power_settlementprice }}</a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table class="custom-table inner-table" v-else>
                                <tbody>
                                    <tr>
                                        <td class="custom-th">
                                            {{ waterpower.water_weight }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="custom-th">
                                            {{ waterpower.water_money }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="custom-th">
                                            {{ waterpower.water_unitconsumption }}
                                        </td>
                                    </tr>
                                    <tr class="border6">
                                        <td class="custom-th">
                                            {{ waterpower.water_settlementprice }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="custom-th">
                                            {{ waterpower.power_weight }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="custom-th">
                                            {{ waterpower.power_money }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="custom-th">
                                            {{ waterpower.power_unitconsumption }}
                                        </td>
                                    </tr>
                                    <tr class="border6">
                                        <td class="custom-th">
                                            {{ waterpower.power_settlementprice }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </template>
                </tr>
                <!-- 人工成本 -->
                <tr>
                    <td class="custom-th custom-th-even">
                        四、直接人工
                    </td>
                    <td class="custom-th padding0">
                        <table class="custom-table inner-table">
                            <tbody>
                                <tr>
                                    <td>总金额(元）</td>
                                </tr>
                                <tr>
                                    <td>单位金额（元/方）</td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                    <template v-for="(item, index) in costsPersonData.costsPersonInfos">
                        <td class="padding0" :key="`costsPersonInfos${index}`">
                            <table class="custom-table inner-table" v-if="isDrill">
                                <tbody>
                                    <tr>
                                        <td @click="showDataDrills1(item,2,4)">
                                            <a href="javascript:;">{{ item.person_money }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills1(item,4,4)">
                                            <a href="javascript:;">{{ item.person_price }}</a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table class="custom-table inner-table" v-else>
                                <tbody>
                                    <tr>
                                        <td>
                                            {{ item.person_money }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ item.person_price }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </template>
                </tr>
                <template v-for="(costsPersonType, costsPersonTypeIndex) in costsPersonData.costsPersonTypeReports">
                    <tr
                        :key="`costsPersonType${costsPersonTypeIndex}`"
                        :class="{'border6': costsPersonTypeIndex===costsPersonData.costsPersonTypeReports.length-1}"
                    >
                        <td class="padding30">
                            {{ costsPersonType.label }}
                        </td>
                        <td class="custom-th padding0">
                            <table class="custom-table inner-table">
                                <tbody>
                                    <tr>
                                        <td>总金额(元）</td>
                                    </tr>
                                    <tr>
                                        <td>单位金额（元/方）</td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <template v-for="(costsPersonDetail, costsPersonDetailIndex) in costsPersonType.costsPersonDetails">
                            <td class="padding0" :key="`costsPersonDetail${costsPersonDetailIndex}`">
                                <table class="custom-table inner-table" v-if="isDrill">
                                    <tbody>
                                        <tr>
                                            <td @click="showDataDrills3(costsPersonDetail,1,1,costsPersonTypeIndex)">
                                                <a href="javascript:;">{{ costsPersonDetail.money }}</a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td @click="showDataDrills3(costsPersonDetail,2,1,costsPersonTypeIndex)">
                                                <a href="javascript:;">{{ costsPersonDetail.price }}</a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table class="custom-table inner-table" v-else>
                                    <tbody>
                                        <tr>
                                            <td>
                                                {{ costsPersonDetail.money }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {{ costsPersonDetail.price }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </template>
                    </tr>
                </template>
                <!-- 制造费用 -->
                <tr>
                    <td class="custom-th custom-th-odd">
                        五、制造费用
                    </td>
                    <td class="custom-th padding0">
                        <table class="custom-table inner-table">
                            <tbody>
                                <tr>
                                    <td>总金额(元）</td>
                                </tr>
                                <tr>
                                    <td>单位金额（元/方）</td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                    <template v-for="(item, index) in costsProduceData.costsProduceInfos">
                        <td class="padding0" :key="`costsProduceInfos${index}`">
                            <table class="custom-table inner-table" v-if="isDrill">
                                <tbody>
                                    <tr>
                                        <td @click="showDataDrills1(item,2,5)">
                                            <a href="javascript:;">{{ item.produce_meney }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills1(item,2,5)">
                                            <a href="javascript:;">{{ item.produce_price }}</a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table class="custom-table inner-table" v-else>
                                <tbody>
                                    <tr>
                                        <td>
                                            {{ item.produce_meney }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ item.produce_price }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </template>
                </tr>
                <template v-for="(costsProduceType, costsProduceTypeIndex) in costsProduceData.costsProduceTypeReports">
                    <tr :key="`costsProduceType${costsProduceTypeIndex}`">
                        <td class="padding30">
                            {{ costsProduceType.label }}
                        </td>
                        <td class="custom-th padding0">
                            <table class="custom-table inner-table">
                                <tbody>
                                    <tr>
                                        <td>总金额(元）</td>
                                    </tr>
                                    <tr>
                                        <td>单位金额（元/方）</td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <template v-for="(costsProduceDetail, costsProduceDetailIndex) in costsProduceType.costsProduceDetails">
                            <td class="padding0" :key="`costsProduceDetail${costsProduceDetailIndex}`">
                                <table class="custom-table inner-table" v-if="isDrill">
                                    <tbody>
                                        <tr>
                                            <td @click="showDataDrills3(costsProduceDetail,1,2,costsProduceTypeIndex)">
                                                <a href="javascript:;">{{ costsProduceDetail.money }}</a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td @click="showDataDrills3(costsProduceDetail,2,2,costsProduceTypeIndex)">
                                                <a href="javascript:;">{{ costsProduceDetail.price }}</a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table class="custom-table inner-table" v-else>
                                    <tbody>
                                        <tr>
                                            <td>
                                                {{ costsProduceDetail.money }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {{ costsProduceDetail.price }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </template>
                    </tr>
                </template>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    // 混凝土生产成本
    name: 'production-costs',
    components: {},
    props: {
        loadObj: {
            type: [Object],
        },
        queryStr: {
            type: [String],
        },
        reportData: {
            type: [Object],
        },
    },
    data() {
        return {
            tableData: {},
            tableHeight: 0,
            directData: { // 直接材料成本
                costsDirectInfos: [],
                costsDirectTypeReports: [],
            },
            waterpowerData: [], // 水电成本
            costsPersonData: {}, // 人工成本
            costsProduceData: {}, // 制造成本
            isDrill: true,
        };
    },
    watch: {
        queryStr: function () {
            this.getDirectCosts();
        },
    },
    computed: {},
    methods: {
        // 月报--直接材料成本
        getDirectCosts() {
            this.$axios
                .get('/interfaceApi/report/costs/report/direct/' + this.queryStr)
                .then(res => {
                    if (res) {
                        this.directData = res;
                        this.getWaterpower();
                    }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 月报--水电成本 
        getWaterpower() {
            this.$axios
                .get('/interfaceApi/report/costs/report/waterpower/' + this.queryStr)
                .then(res => {
                    if (res) {
                        this.waterpowerData = res;
                        this.getPersonData();
                    }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 月报--人工成本
        getPersonData() {
            this.$axios
                .get('/interfaceApi/report/costs/report/person/' + this.queryStr)
                .then(res => {
                    if (res) {
                        this.costsPersonData = res;
                        this.getProduceData();
                    }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 月报--制造成本
        getProduceData() {
            this.$axios
                .get('/interfaceApi/report/costs/report/produce/' + this.queryStr)
                .then(res => {
                    if (res) {
                        this.costsProduceData = res;
                        // this.loadObj.close();
                    }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 数据钻取
        // eslint-disable-next-line max-params
        showDataDrills1(rowData, field, type) {

            let parsetText = null;
            let showType = null;
            switch (field) {
                case 1:
                    parsetText = '总用量(吨)';
                    break;
                case 2:
                    parsetText = '总金额(元）';
                    showType = 2;
                    break;
                case 3:
                    parsetText = '单耗（吨/方）';
                    showType = 2;
                    break;
                case 4:
                    parsetText = '单位金额（元/方）';
                    showType = 2;
                    break;
                case 5:
                    parsetText = '结算价格(元/吨)';
                    showType = 2;
                    break;
                case 6:
                    parsetText = '容重（吨/方）';
                    showType = 2;
                    break;

            }
            let istotal = 0;
            let month = 0;
            if (rowData.date === 0) {
                istotal = 1;
                if (this.reportData.month === 0) {
                    month = 12;
                } else {
                    month = this.reportData.month;
                }
            } else {
                istotal = 0;
                month = rowData.date;
            }
            const params = {
                field: field,
                text: parsetText,
                year: this.reportData.year,
                month: month,
                business_code: 'yyqk',
                istotal: istotal,
                treeid: this.reportData.treeid,
                type: type,
                firstPop: true,
                sourceReport: 'costs',
                showType: showType,
            };
            const pop = this.$toast({
                title: false,
                text: '按各企业上报数据钻取分析',
                t_url: 'reportManage/operationTrends/summary/dataDrills/DrillDownPop',
                type: 'eject',
                width: '80%',
                extr: { closeBtn: { width: '.4rem', height: '.4rem', 'line-height': '.4rem' }, params: params },
            });
            window.window.$globalHub.$store.commit('setChartPop', pop);
        },
        // 数据钻取
        // eslint-disable-next-line max-params
        showDataDrills2(rowData, field, datatype) {
            let parsetText = null;
            let showType = null;
            switch (field) {
                case 1:
                    parsetText = '总用量(吨)';
                    break;
                case 2:
                    parsetText = '总金额(元）';
                    showType = 2;
                    break;
                case 3:
                    parsetText = '单耗（吨/方）';
                    showType = 2;
                    break;
                case 4:
                    parsetText = '结算价格(元/吨)';
                    showType = 2;
                    break;

            }
            let istotal = 0;
            let month = 0;
            if (rowData.date === 0) {
                istotal = 1;
                if (this.reportData.month === 0) {
                    month = 12;
                } else {
                    month = this.reportData.month;
                }
            } else {
                istotal = 0;
                month = rowData.date;
            }
            let type = null;
            let code = null;
            if (rowData.direct_type) {
                type = rowData.direct_type;
                code = rowData.code;
            } else {
                type = rowData.type_code;
            }
            const params = {
                field: field,
                text: parsetText,
                year: this.reportData.year,
                month: month,
                business_code: 'yyqk',
                istotal: istotal,
                treeid: this.reportData.treeid,
                datatype: datatype,
                firstPop: true,
                type: type,
                code: code,
                sourceReport: 'direct',
                showType: showType,
            };
            const pop = this.$toast({
                title: false,
                text: '按各企业上报数据钻取分析',
                t_url: 'reportManage/operationTrends/summary/dataDrills/DrillDownPop',
                type: 'eject',
                width: '80%',
                extr: { closeBtn: { width: '.4rem', height: '.4rem', 'line-height': '.4rem' }, params: params },
            });
            window.window.$globalHub.$store.commit('setChartPop', pop);
        },
        // 数据钻取
        // eslint-disable-next-line max-params
        showDataDrills3(rowData, field, datatype, type) {

            let parsetText = null;
            let showType = null;
            switch (field) {
                case 1:
                    parsetText = '总金额(元）';
                    showType = 2;
                    break;
                case 2:
                    parsetText = '单位金额（元/方）';
                    showType = 2;
                    break;

            }
            let istotal = 0;
            let month = 0;
            if (rowData.date === 0) {
                istotal = 1;
                if (this.reportData.month === 0) {
                    month = 12;
                } else {
                    month = this.reportData.month;
                }
            } else {
                istotal = 0;
                month = rowData.date;
            }

            const params = {
                field: field,
                text: parsetText,
                year: this.reportData.year,
                month: month,
                business_code: 'yyqk',
                istotal: istotal,
                treeid: this.reportData.treeid,
                datatype: datatype,
                firstPop: true,
                type: type,
                sourceReport: 'perpro',
                showType: showType,
            };
            const pop = this.$toast({
                title: false,
                t_url: 'reportManage/operationTrends/summary/dataDrills/DrillDownPop',
                type: 'eject',
                width: '80%',
                extr: { closeBtn: { width: '.6rem', height: '.6rem', 'line-height': '.6rem' }, params: params },
            });
            window.window.$globalHub.$store.commit('setChartPop', pop);
        },
        isDrillFn() {
            if (this.reportData.org_type === 5 || this.reportData.org_type === 6) {
                this.isDrill = false;
            } else {
                this.isDrill = true;
            }
        },
    },
    created() {
        this.getDirectCosts();
    },
    mounted() {
        this.isDrillFn();
    },
};
</script>
<style>
    /* .main .main-bottom .edit-table .wrapper .custom-table td, .main .main-bottom .edit-table .wrapper .custom-table th{
        text-align: left;
        padding-left: .1rem;
    } */
    .padding20{
        padding-left: .2rem!important;
    }
    .padding30{
        padding-left: .3rem!important;
    }
</style>